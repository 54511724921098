import { useTranslation } from "react-i18next";
import { EditorPagesSidebarTabContentFragment } from "../client/generated";
import SidebarTabContent from "../shell/SidebarTabContent";
import { TabIdentifier } from "./reducers";
import SidebarDivider from "../shell/SidebarDivider";
import PageThumbnail from "../pages/PageThumbnail";
import PagesOverviewNavigationInput from "../pages/PagesOverviewNavigationInput";

type Props = {
  fragment: EditorPagesSidebarTabContentFragment;
};

export default function EditorPagesSidebarTabContent({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabContent
      value={TabIdentifier.Pages}
      title={t("EditorPagesSidebarTabContent.title")}
    >
      <div className="flex-1 flex-col flex gap-4 p-4 overflow-auto">
        {fragment.document.pages.pages.map((page) => (
          <PageThumbnail key={page.id} fragment={page} />
        ))}
      </div>
      <div>
        <SidebarDivider />
        <PagesOverviewNavigationInput key={fragment.id} fragment={fragment} />
      </div>
    </SidebarTabContent>
  );
}
