import { mdiFileMultipleOutline } from "@mdi/js";
import { useTranslation } from "react-i18next";
import SidebarTabButton from "../shell/SidebarTabButton";
import { TabIdentifier } from "./reducers";

type Props = {
  disabled?: boolean;
};

export default function EditorPagesSidebarTabButton({
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabButton
      tooltip={t("EditorPagesSidebarTabButton.tooltip")}
      value={TabIdentifier.Pages}
      iconPath={mdiFileMultipleOutline}
      disabled={disabled}
    />
  );
}
