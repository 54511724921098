import { AccessLoading } from "@acdc2/ui/components/access-loading";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@acdc2/ui/components/tooltip";

type Props = {
  children: React.ReactNode;
  tooltip: string;
  loading?: boolean;
};

export default function SidebarButton({
  children,
  tooltip,
  loading,
}: Props): JSX.Element {
  return (
    <div className="relative">
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger asChild>{children}</TooltipTrigger>
          <TooltipContent side="right" className="m-3">
            {tooltip}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      {loading && (
        <div className="absolute flex justify-center items-center left-0 right-0 top-0 bottom-0">
          <AccessLoading />
        </div>
      )}
    </div>
  );
}
