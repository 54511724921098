import { useTranslation } from "react-i18next";
import AttributePreview from "../AttributePreview";
import { HeadingLevelPreviewFragment } from "../../client/generated";

type Props = {
  fragment: HeadingLevelPreviewFragment;
};

export default function HeadingLevelPreview({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <AttributePreview
      label={t("HeadingLevelPreview.label")}
      preview={t("HeadingLevelPreview.preview", { level: fragment.level })}
    />
  );
}
