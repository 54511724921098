import { useTranslation } from "react-i18next";
import AttributePreview from "../AttributePreview";
import { ListItemPreviewFragment } from "../../client/generated";

type Props = {
  fragment: ListItemPreviewFragment;
};

export default function ListItemPreview({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <AttributePreview
      label={t("ListItemPreview.label")}
      preview={t("ListItemPreview.preview", { number: fragment.ordinal })}
    />
  );
}
