import { Table } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { DocumentRowFragment } from "../client/generated";
import DocumentTableFilterInput from "./DocumentTableFilterInput";

type Props = {
  table: Table<DocumentRowFragment>;
};

export default function DocumentTableFilters({ table }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="flex justify-end gap-4">
      <DocumentTableFilterInput
        column={table.getColumn("name")!}
        placeholder={t("DocumentTable.filters.title")}
      />
      <DocumentTableFilterInput
        column={table.getColumn("ownerUsername")!}
        placeholder={t("DocumentTable.filters.uploader")}
      />
      <DocumentTableFilterInput
        column={table.getColumn("extension")!}
        placeholder={t("DocumentTable.filters.extension")}
      />
    </div>
  );
}
