import { mdiHuman } from "@mdi/js";
import { useTranslation } from "react-i18next";
import SidebarTabButton from "../shell/SidebarTabButton";
import { TabIdentifier } from "./reducers";

type Props = {
  disabled?: boolean;
};

export default function EditorCheckerSidebarTabButton({
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabButton
      tooltip={t("EditorCheckerSidebarTabButton.tooltip")}
      value={TabIdentifier.Checker}
      iconPath={mdiHuman}
      disabled={disabled}
    />
  );
}
