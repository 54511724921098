import { useNavigate } from "react-router-dom";
import { Button } from "@acdc2/ui/components/button";
import { useTranslation } from "react-i18next";
import Sidebar from "../shell/Sidebar";
import Scaffold from "../shell/Scaffold";
import { routingPaths } from "../navigation/constants";

type Props = {
  error: unknown;
  componentStack: string;
  eventId: string;
  resetError(): void;
};

export default function ErrorScreen({
  error,
  componentStack,
  eventId,
  resetError,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClick = () => {
    resetError();
    navigate(routingPaths.index);
  };

  return (
    <Scaffold sidebar={<Sidebar />}>
      <div className="flex-1 flex flex-col justify-center items-center gap-4 p-4">
        <div className="text-lg font-bold uppercase">
          {t("ErrorScreen.title")}
        </div>
        <div>{t("ErrorScreen.reference", { id: eventId })}</div>
        <details className="max-w-6xl">
          <summary className="text-center cursor-pointer">
            {t("ErrorScreen.details")}
          </summary>
          <div className="flex flex-col gap-4 font-mono">
            <div className="bg-gray-50 p-4">{new String(error)}</div>
            <div className="bg-gray-50 p-4">{componentStack}</div>
          </div>
        </details>
        <Button onClick={onClick}>Go back</Button>
      </div>
    </Scaffold>
  );
}
