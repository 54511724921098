import { useTheme } from "@acdc2/ui/components/theme-provider";
import { mdiThemeLightDark } from "@mdi/js";
import { useTranslation } from "react-i18next";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@acdc2/ui/components/dropdown-menu";
import SidebarButtonIcon from "./SidebarButtonIcon";
import SidebarButton from "./SidebarButton";

export default function ViewerThemeButton(): JSX.Element {
  const { t } = useTranslation();
  const { setTheme, theme } = useTheme();

  const onValueChange = (value: string) => {
    if (value === "dark" || value === "light" || value === "system") {
      setTheme(value);
    } else {
      console.error("Invalid theme value");
    }
  };

  return (
    <DropdownMenu>
      <SidebarButton tooltip={t("ViewerThemeButton.title")}>
        <DropdownMenuTrigger asChild>
          <SidebarButtonIcon path={mdiThemeLightDark} />
        </DropdownMenuTrigger>
      </SidebarButton>
      <DropdownMenuContent
        className="my-2"
        side="right"
        onCloseAutoFocus={(e) => e.preventDefault()}
      >
        <DropdownMenuLabel children={t("ViewerThemeButton.title")} />
        <DropdownMenuSeparator />
        <DropdownMenuRadioGroup value={theme} onValueChange={onValueChange}>
          <DropdownMenuRadioItem
            value="light"
            children={t("ViewerThemeButton.items.light")}
          />
          <DropdownMenuRadioItem
            value="dark"
            children={t("ViewerThemeButton.items.dark")}
          />
          <DropdownMenuRadioItem
            value="system"
            children={t("ViewerThemeButton.items.system")}
          />
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
