import { AccessLoading } from "@acdc2/ui/components/access-loading";
import { useTranslation } from "react-i18next";
import Scaffold from "../shell/Scaffold";
import Sidebar from "../shell/Sidebar";

export default function LoadingScreen(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Scaffold sidebar={<Sidebar />}>
      <div className="flex-1 flex flex-col justify-center items-center gap-4">
        <AccessLoading />
        <span className="text-lg font-bold uppercase">
          {t("LoadingScreen.label")}
        </span>
      </div>
    </Scaffold>
  );
}
