import { useTranslation } from "react-i18next";
import AttributePreview from "../AttributePreview";
import { ExpressionPreviewFragment } from "../../client/generated";

type Props = {
  fragment: ExpressionPreviewFragment;
};

export default function ExpressionPreview({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <AttributePreview
      label={t("ExpressionPreview.label")}
      preview={fragment.expressionLatex}
    />
  );
}
