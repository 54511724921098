import * as Tabs from "@radix-ui/react-tabs";
import SidebarLogo from "./SidebarLogo";
import ViewerAccountButton from "./ViewerAccountButton";
import ViewerLocaleButton from "./ViewerLocaleButton";
import ViewerThemeButton from "./ViewerThemeButton";

type Props = {
  buttons?: React.ReactNode;
  content?: React.ReactNode;
  value?: string;
  onValueChange?: (value: string) => void;
};

export default function Sidebar({
  buttons,
  content,
  value,
  onValueChange,
}: Props): JSX.Element {
  return (
    <Tabs.Root
      value={value}
      onValueChange={onValueChange}
      orientation="vertical"
      asChild
    >
      <aside className="flex flex-row">
        <nav className="w-14 border-r flex flex-col">
          <SidebarLogo />
          <Tabs.List className="flex-1 border-y flex flex-col items-center gap-2 py-2">
            {buttons}
          </Tabs.List>
          <div className="p-2 flex flex-col items-center gap-2">
            <ViewerLocaleButton />
            <ViewerThemeButton />
            <ViewerAccountButton />
          </div>
        </nav>
        <div className="flex flex-col">{content}</div>
      </aside>
    </Tabs.Root>
  );
}
