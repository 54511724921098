import { useTranslation } from "react-i18next";
import AttributePreview from "../AttributePreview";
import { TablePreviewFragment } from "../../client/generated";

type Props = {
  fragment: TablePreviewFragment;
};

export default function TablePreview({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <AttributePreview
      label={t("TablePreview.label")}
      preview={`${fragment.rows}x${fragment.columns}`}
    />
  );
}
