import { useParams } from "react-router-dom";
import { useDocumentEditorScreenSuspenseQuery } from "../client/generated";
import Sidebar from "../shell/Sidebar";
import Scaffold from "../shell/Scaffold";
import EditorMetadataSidebarTabButton from "../editor/EditorMetadataSidebarTabButton";
import EditorMetadataSidebarTabContent from "../editor/EditorMetadataSidebarTabContent";
import PageThumbnail from "../pages/PageThumbnail";
import OverviewSidebarButton from "../editor/OverviewSidebarButton";
import EditorPagesSidebarTabButton from "../editor/EditorPagesSidebarTabButton";
import EditorRegionsSidebarTabButton from "../editor/EditorRegionsSidebarTabButton";
import EditorAttributesSidebarTabButton from "../editor/EditorAttributesSidebarTabButton";
import EditorReadingOrderSidebarTabButton from "../editor/EditorReadingOrderSidebarTabButton";
import SidebarDivider from "../shell/SidebarDivider";
import PreviousPageSidebarButton from "../editor/PreviousPageSidebarButton";
import NextPageSidebarButton from "../editor/NextPageSidebarButton";
import { TabIdentifier } from "../editor/reducers";
import EditorCheckerSidebarTabButton from "../editor/EditorCheckerSidebarTabButton";
import EditorCheckerSidebarTabContent from "../editor/EditorCheckerSidebarTabContent";
import { useState } from "react";

export default function DocumentEditorScreen(): JSX.Element {
  const [tabValue, setTabValue] = useState(TabIdentifier.Checker);
  const { documentId } = useParams<{ documentId: string }>();
  const { data } = useDocumentEditorScreenSuspenseQuery({
    variables: { documentId: documentId! },
  });

  if (data.node?.__typename !== "Document") {
    throw TypeError("Document not found");
  }

  return (
    <Scaffold
      sidebar={
        <Sidebar
          value={tabValue}
          onValueChange={(newTabValue) => {
            setTabValue(newTabValue as TabIdentifier);
          }}
          buttons={
            <>
              <OverviewSidebarButton fragment={data.node} disabled />
              <PreviousPageSidebarButton />
              <NextPageSidebarButton />
              <SidebarDivider />
              <EditorCheckerSidebarTabButton />
              <EditorMetadataSidebarTabButton />
              <EditorPagesSidebarTabButton disabled />
              <EditorRegionsSidebarTabButton disabled />
              <EditorAttributesSidebarTabButton disabled />
              <EditorReadingOrderSidebarTabButton disabled />
            </>
          }
          content={
            <>
              <EditorCheckerSidebarTabContent />
              <EditorMetadataSidebarTabContent fragment={data.node} />
            </>
          }
        />
      }
    >
      <div className="p-4 flex-1 flex flex-col gap-4 bg-gray-100 dark:bg-gray-900">
        <h1 className="text-lg">{data.node.name}</h1>
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
          {data.node.pages.pages.map((page) => (
            <PageThumbnail key={page.id} fragment={page} />
          ))}
        </div>
      </div>
    </Scaffold>
  );
}
