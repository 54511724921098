import { mdiTagOutline } from "@mdi/js";
import { Icon } from "@mdi/react";

type Props = {
  label: string;
  preview?: string;
};

export default function AttributePreview({
  label,
  preview,
}: Props): JSX.Element {
  return (
    <div className="flex">
      <div className="flex items-center gap-1 p-2 border-r">
        <Icon path={mdiTagOutline} size="1rem" />
        <span className="font-bold text-xs uppercase">{label}</span>
      </div>
      <div className="p-2 flex-1 truncate text-right text-xs">{preview}</div>
    </div>
  );
}
