import { useTranslation } from "react-i18next";
import AttributePreview from "../AttributePreview";
import { TableCellPreviewFragment } from "../../client/generated";

type Props = {
  fragment: TableCellPreviewFragment;
};

export default function TableCellPreview({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <AttributePreview
      label={t("TableCellPreview.label")}
      preview={`${fragment.rowSpan}x${fragment.columnSpan}`}
    />
  );
}
