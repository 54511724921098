import { Dispatch, useState } from "react";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";
import { Textarea } from "@acdc2/ui/components/textarea";
import {
  EditorAction,
  PaneIdentifier,
  TabIdentifier,
} from "../../editor/reducers";
import AttributesPaneControls from "../AttributesPaneControls";
import AttributesPane from "../AttributesPane";
import {
  AlternativeTextUpdatePaneFragment,
  useUpdateAlternativeTextAttributeMutation,
} from "../../client/generated";

type Props = {
  fragment: AlternativeTextUpdatePaneFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function AlternativeTextUpdatePane({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [alternativeText, setAlternativeText] = useState(
    fragment.alternativeText,
  );

  const [alternativeTextUpdate, { loading }] =
    useUpdateAlternativeTextAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await alternativeTextUpdate({
      variables: { attributeId: fragment.id, alternativeText },
    });

    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: PaneIdentifier.Default,
      },
    });
  };

  return (
    <AttributesPane value={fragment.id}>
      <AttributesPaneControls dispatch={dispatch} />
      <form className="p-2 flex flex-col gap-2" onSubmit={onSubmit}>
        <Textarea
          required
          value={alternativeText}
          onChange={(event) => setAlternativeText(event.target.value)}
          rows={8}
        />
        <AccessButton type="submit" loading={loading}>
          {t("AlternativeTextUpdatePane.labels.confirm")}
        </AccessButton>
      </form>
    </AttributesPane>
  );
}
