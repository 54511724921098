import { useTranslation } from "react-i18next";
import { AlternativeTextPreviewFragment } from "../../client/generated";
import AttributePreview from "../AttributePreview";

type Props = {
  fragment: AlternativeTextPreviewFragment;
};

export default function AlternativeTextPreview({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <AttributePreview
      label={t("AlternativeTextPreview.label")}
      preview={fragment.alternativeText}
    />
  );
}
