import { useTranslation } from "react-i18next";
import { mdiChevronRight } from "@mdi/js";
import SidebarButton from "../shell/SidebarButton";
import SidebarButtonIcon from "../shell/SidebarButtonIcon";
import { NextPageSidebarButtonFragment } from "../client/generated";
import { routingPaths } from "../navigation/constants";
import { useNavigateTransition } from "../navigation/useNavigateTransition";

type Props = {
  fragment?: NextPageSidebarButtonFragment;
  disabled?: boolean;
};

export default function NextPageSidebarButton({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [isPending, navigate] = useNavigateTransition();

  const onClick = () => {
    if (fragment && fragment.nextPage) {
      navigate(
        routingPaths.documentPage(fragment.document.id, fragment.nextPage.id),
      );
    }
  };

  return (
    <SidebarButton
      tooltip={t("PageSidebarTabNextButton.tooltip")}
      loading={isPending}
    >
      <SidebarButtonIcon
        path={mdiChevronRight}
        disabled={!fragment?.nextPage}
        onClick={onClick}
      />
    </SidebarButton>
  );
}
