import { useParams } from "react-router-dom";
import { useReducer } from "react";
import { usePageEditorScreenSuspenseQuery } from "../client/generated";
import {
  editorReducer,
  TabIdentifier,
  RegionMode,
  PaneIdentifier,
} from "../editor/reducers";
import Sidebar from "../shell/Sidebar";
import Scaffold from "../shell/Scaffold";
import EditorMetadataSidebarTabButton from "../editor/EditorMetadataSidebarTabButton";
import EditorMetadataSidebarTabContent from "../editor/EditorMetadataSidebarTabContent";
import EditorRegionsSidebarTabButton from "../editor/EditorRegionsSidebarTabButton";
import EditorRegionsSidebarTabContent from "../editor/EditorRegionsSidebarTabContent";
import EditorReadingOrderSidebarTabButton from "../editor/EditorReadingOrderSidebarTabButton";
import EditorReadingOrderSidebarTabContent from "../editor/EditorReadingOrderSidebarTabContent";
import EditorPagesSidebarTabButton from "../editor/EditorPagesSidebarTabButton";
import EditorPagesSidebarTabContent from "../editor/EditorPagesSidebarTabContent";
import EditorAttributesSidebarTabButton from "../editor/EditorAttributesSidebarTabButton";
import EditorAttributesSidebarTabContent from "../editor/EditorAttributesSidebarTabContent";
import EditorCheckerSidebarTabButton from "../editor/EditorCheckerSidebarTabButton";
import EditorCheckerSidebarTabContent from "../editor/EditorCheckerSidebarTabContent";
import OverviewSidebarButton from "../editor/OverviewSidebarButton";
import SidebarDivider from "../shell/SidebarDivider";
import PageEditor from "../editor/PageEditor";
import PreviousPageSidebarButton from "../editor/PreviousPageSidebarButton";
import NextPageSidebarButton from "../editor/NextPageSidebarButton";

export default function PageEditorScreen(): JSX.Element {
  const [state, dispatch] = useReducer(editorReducer, {
    mode: RegionMode.Rectangle,
    paneState: {
      tabIdentifier: TabIdentifier.Checker,
      paneIdentifier: PaneIdentifier.Default,
    },
  });
  const { documentId, pageId } = useParams<{
    documentId: string;
    pageId: string;
  }>();

  const { data } = usePageEditorScreenSuspenseQuery({
    variables: { documentId: documentId!, pageId: pageId! },
  });

  if (
    data.document?.__typename !== "Document" ||
    data.page?.__typename !== "Page"
  ) {
    throw TypeError("Document or Page not found");
  }

  return (
    <Scaffold
      sidebar={
        <Sidebar
          value={state.paneState.tabIdentifier}
          onValueChange={(value) => {
            dispatch({
              type: "switchPane",
              paneState: {
                tabIdentifier: value as TabIdentifier,
                paneIdentifier: PaneIdentifier.Default,
              },
            });
          }}
          buttons={
            <>
              <OverviewSidebarButton fragment={data.document} />
              <PreviousPageSidebarButton fragment={data.page} />
              <NextPageSidebarButton fragment={data.page} />
              <SidebarDivider />
              <EditorCheckerSidebarTabButton />
              <EditorMetadataSidebarTabButton />
              <EditorPagesSidebarTabButton />
              <EditorRegionsSidebarTabButton />
              <EditorAttributesSidebarTabButton />
              <EditorReadingOrderSidebarTabButton />
            </>
          }
          content={
            <>
              <EditorCheckerSidebarTabContent />
              <EditorMetadataSidebarTabContent fragment={data.document} />
              <EditorPagesSidebarTabContent fragment={data.page} />
              <EditorRegionsSidebarTabContent
                state={state}
                dispatch={dispatch}
                fragment={data.page}
              />
              <EditorAttributesSidebarTabContent
                state={state}
                dispatch={dispatch}
              />
              <EditorReadingOrderSidebarTabContent />
            </>
          }
        />
      }
    >
      <PageEditor fragment={data.page} state={state} dispatch={dispatch} />
    </Scaffold>
  );
}
