import { useNavigate } from "react-router-dom";
import { mdiViewGridOutline } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { OverviewSidebarButtonFragment } from "../client/generated";
import SidebarButton from "../shell/SidebarButton";
import SidebarButtonIcon from "../shell/SidebarButtonIcon";
import { routingPaths } from "../navigation/constants";

type Props = {
  fragment: OverviewSidebarButtonFragment;
  disabled?: boolean;
};

export default function OverviewSidebarButton({
  fragment,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClick = () => {
    navigate(routingPaths.document(fragment.id));
  };

  return (
    <SidebarButton tooltip={t("OverviewSidebarButton.tooltip")}>
      <SidebarButtonIcon
        path={mdiViewGridOutline}
        onClick={onClick}
        disabled={disabled}
      />
    </SidebarButton>
  );
}
