import { Icon } from "@mdi/react";
import { mdiLogin } from "@mdi/js";
import { Button } from "@acdc2/ui/components/button";
import { useAuth } from "react-oidc-context";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { routingPaths } from "../navigation/constants";
import { useNavigateTransition } from "../navigation/useNavigateTransition";
import Scaffold from "../shell/Scaffold";
import Sidebar from "../shell/Sidebar";
import Shell from "../shell/Shell";
import LoadingScreen from "./LoadingScreen";

export default function LoginScreen(): JSX.Element {
  const auth = useAuth();
  const { t } = useTranslation();
  const [isPending, navigate] = useNavigateTransition();

  useEffect(() => {
    if (auth.isLoading) return;
    if (auth.error) return;
    if (!auth.isAuthenticated) return;
    navigate(routingPaths.documents, { replace: true });
  }, [auth.isLoading, auth.isAuthenticated, auth.error, navigate]);

  if (auth.error) {
    throw auth.error;
  }

  // react-oidc-context is not compatible with react suspense yet
  if (auth.isLoading || auth.isAuthenticated || isPending) {
    return <LoadingScreen />;
  }

  return (
    <Shell>
      <Scaffold sidebar={<Sidebar />}>
        <div className="flex-1 grid lg:grid-cols-[2fr_1fr]">
          <div className="flex flex-col items-center justify-center p-6 lg:p-12 border-r border-gray-200 dark:border-gray-800">
            <div className="max-w-2xl space-y-6 text-center">
              <h1 className="text-4xl font-extrabold tracking-tight sm:text-6xl">
                {t("LoginScreen.teaser.heading")}
              </h1>
              <p className="text-gray-500 dark:text-gray-400 text-lg">
                {t("LoginScreen.teaser.problem")}
              </p>
              <p className="text-gray-500 dark:text-gray-400 text-lg">
                {t("LoginScreen.teaser.solution")}
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center p-6 lg:p-12">
            <div className="max-w-md space-y-6">
              <div className="space-y-4 text-center">
                <h2 className="text-3xl font-bold">
                  {t("LoginScreen.login.heading")}
                </h2>
                <p className="text-gray-500 dark:text-gray-400 text-lg">
                  {t("LoginScreen.login.description")}
                </p>
              </div>
              <Button
                className="w-full flex items-center justify-center gap-2"
                size="lg"
                variant="outline"
                onClick={() => auth.signinPopup()}
              >
                <Icon path={mdiLogin} size={1} />
                <span className="font-bold">
                  {t("LoginScreen.login.button")}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </Scaffold>
    </Shell>
  );
}
