import { Dispatch } from "react";
import { RegionPreviewFragment } from "../client/generated";
import { EditorAction, EditorState } from "../editor/reducers";
import RegionThumbnail from "./RegionThumbnail";
import { cn } from "@acdc2/ui/lib/utils";
import AlternativeTextPreview from "../attributes/alternative-text/AlternativeTextPreview";
import CaptionPreview from "../attributes/caption/CaptionPreview";
import ExcludeReadingOrderPreview from "../attributes/exclude-reading-order/ExcludeReadingOrderPreview";
import ExpressionPreview from "../attributes/expression/ExpressionPreview";
import FigurePreview from "../attributes/figure/FigurePreview";
import HeadingLevelPreview from "../attributes/heading-level/HeadingLevelPreview";
import ImagePreview from "../attributes/image/ImagePreview";
import ListPreview from "../attributes/list/ListPreview";
import ListItemPreview from "../attributes/list-item/ListItemPreview";
import PaginationArtifactPreview from "../attributes/pagination-artifact/PaginationArtifactPreview";
import TablePreview from "../attributes/table/TablePreview";
import TableCellPreview from "../attributes/table-cell/TableCellPreview";
import TableHeaderCellPreview from "../attributes/table-header-cell/TableHeaderCellPreview";
import TableOfContentsPreview from "../attributes/table-of-contents/TableOfContentsPreview";
import TableOfContentsItemPreview from "../attributes/table-of-contents-item/TableOfContentsItemPreview";
import TextPreview from "../attributes/text/TextPreview";

type Props = {
  dispatch: Dispatch<EditorAction>;
  state: EditorState;
  fragment: RegionPreviewFragment;
};

export default function RegionPreview({
  fragment,
  dispatch,
  state,
}: Props): JSX.Element {
  const isSelected = state.selectedRegionId === fragment.id;

  const onClick = () => {
    dispatch({
      type: "selectRegion",
      regionId: fragment.id,
    });
  };

  return (
    <div
      className={cn(
        "border rounded overflow-hidden bg-gray-50 dark:bg-gray-900",
        {
          "outline outline-brand": isSelected,
        },
      )}
    >
      <div className="cursor-pointer" onClick={onClick}>
        <RegionThumbnail fragment={fragment} />
      </div>
      <div>
        {fragment.attributes.map((attribute) => (
          <div key={attribute.id} className="border-t">
            {attribute.__typename === "AlternativeTextAttribute" ? (
              <AlternativeTextPreview fragment={attribute} />
            ) : attribute.__typename === "CaptionAttribute" ? (
              <CaptionPreview />
            ) : attribute.__typename === "ExcludeReadingOrderAttribute" ? (
              <ExcludeReadingOrderPreview />
            ) : attribute.__typename === "ExpressionAttribute" ? (
              <ExpressionPreview fragment={attribute} />
            ) : attribute.__typename === "FigureAttribute" ? (
              <FigurePreview />
            ) : attribute.__typename === "HeaderLevelAttribute" ? (
              <HeadingLevelPreview fragment={attribute} />
            ) : attribute.__typename === "ImageAttribute" ? (
              <ImagePreview />
            ) : attribute.__typename === "ListAttribute" ? (
              <ListPreview fragment={attribute} />
            ) : attribute.__typename === "ListItemAttribute" ? (
              <ListItemPreview fragment={attribute} />
            ) : attribute.__typename === "PaginationArtifactAttribute" ? (
              <PaginationArtifactPreview fragment={attribute} />
            ) : attribute.__typename === "TableAttribute" ? (
              <TablePreview fragment={attribute} />
            ) : attribute.__typename === "TableCellAttribute" ? (
              <TableCellPreview fragment={attribute} />
            ) : attribute.__typename === "TableHeaderCellAttribute" ? (
              <TableHeaderCellPreview fragment={attribute} />
            ) : attribute.__typename === "TableOfContentsAttribute" ? (
              <TableOfContentsPreview />
            ) : attribute.__typename === "TableOfContentsItemAttribute" ? (
              <TableOfContentsItemPreview />
            ) : attribute.__typename === "TextAttribute" ? (
              <TextPreview fragment={attribute} />
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}
