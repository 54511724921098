import { Dispatch, useState } from "react";
import {
  EditorAction,
  PaneIdentifier,
  TabIdentifier,
} from "../../editor/reducers";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";
import CaptionLinkedRegionSelect from "./CaptionRegionSelect";
import {
  CaptionCreationPaneFragment,
  useCreateCaptionAttributeMutation,
} from "../../client/generated";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: CaptionCreationPaneFragment;
};

export default function CaptionCreationPane({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [linkedRegion, setLinkedRegion] = useState<string>();

  const [createCaptionAttribute] = useCreateCaptionAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await createCaptionAttribute({
      variables: {
        regionId: fragment.id,
        linkedRegionId: linkedRegion,
      },
    });

    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: PaneIdentifier.Default,
      },
    });
  };

  return (
    <AttributesPane value={PaneIdentifier.CaptionCreation}>
      <AttributesPaneControls dispatch={dispatch} />
      <form className="p-2 flex flex-col gap-2" onSubmit={onSubmit}>
        <CaptionLinkedRegionSelect
          fragment={fragment}
          value={linkedRegion}
          onValueChange={setLinkedRegion}
        />
        <AccessButton type="submit">
          {t("CaptionCreationPane.labels.add")}
        </AccessButton>
      </form>
    </AttributesPane>
  );
}
