import { Dispatch } from "react";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";
import {
  EditorAction,
  PaneIdentifier,
  TabIdentifier,
} from "../../editor/reducers";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";
import {
  ImageCreationPaneFragment,
  useCreateImageAttributeMutation,
} from "../../client/generated";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: ImageCreationPaneFragment;
};

export default function ImageCreationPane({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [createImageAttribute] = useCreateImageAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await createImageAttribute({
      variables: {
        regionId: fragment.id,
      },
    });

    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: PaneIdentifier.Default,
      },
    });
  };

  return (
    <AttributesPane value={PaneIdentifier.ImageCreation}>
      <AttributesPaneControls dispatch={dispatch} />
      <form onSubmit={onSubmit} className="p-2 flex flex-col">
        <AccessButton type="submit">
          {t("ImageCreationPane.labels.add")}
        </AccessButton>
      </form>
    </AttributesPane>
  );
}
