import { Skeleton } from "@acdc2/ui/components/skeleton";
import { PageSkeletonFragment } from "../client/generated";

type Props = {
  fragment: PageSkeletonFragment;
};

export default function PageSkeleton({ fragment }: Props): JSX.Element {
  return (
    <div
      style={{ aspectRatio: `${fragment.width}/${fragment.height}` }}
      className="h-full bg-white shadow flex flex-col space-y-4 px-16 py-8"
    >
      <div className="flex justify-between">
        <Skeleton className="h-4 w-4" />
        <Skeleton className="h-4 w-8" />
      </div>

      <Skeleton className="h-1 w-full" />

      <div className="flex space-x-2">
        <Skeleton className="h-6 w-6" />
        <Skeleton className="h-6 w-4/12" />
      </div>

      <div className="flex flex-col space-y-2">
        <Skeleton className="h-4 w-full" />
        <Skeleton className="h-4 w-full" />
        <Skeleton className="h-4 w-full" />
        <Skeleton className="h-4 w-full" />
        <Skeleton className="h-4 w-7/12" />
      </div>

      <div className="flex flex-col space-y-2">
        <Skeleton className="h-4 w-full" />
        <Skeleton className="h-4 w-full" />
        <Skeleton className="h-4 w-9/12" />
      </div>
    </div>
  );
}
