import * as Tabs from "@radix-ui/react-tabs";
import { PaneIdentifier } from "../editor/reducers";

type Props = {
  value: PaneIdentifier | string;
  children: React.ReactNode;
};

export default function AttributesPane({
  value,
  children,
}: Props): JSX.Element {
  return (
    <Tabs.Content value={value} asChild>
      <div className="h-full flex flex-col">{children}</div>
    </Tabs.Content>
  );
}
