import * as Tabs from "@radix-ui/react-tabs";
import { Suspense } from "react";
import SidebarLoading from "./SidebarLoading";

type Props = {
  value: string;
  title: string;
  children?: React.ReactNode;
};

export default function SidebarTabContent({
  value,
  title,
  children,
}: Props): JSX.Element {
  return (
    <Tabs.Content value={value} asChild>
      <div className="h-full w-72 border-r flex flex-col">
        <div className="border-b h-14 p-2 flex justify-center items-center">
          <h1 className="text-lg">{title}</h1>
        </div>
        <div className="flex-1 overflow-auto flex flex-col">
          <Suspense fallback={<SidebarLoading />}>{children}</Suspense>
        </div>
      </div>
    </Tabs.Content>
  );
}
