import "@acdc2/ui/globals.css";
import "./i18n";
import * as Sentry from "@sentry/react";
import React from "react";
import * as ReactDOM from "react-dom/client";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@acdc2/ui/components/theme-provider";
import { AuthProvider } from "react-oidc-context";
import { BrowserRouter } from "react-router-dom";
import App from "./App.tsx";
import { onSigninCallback, userManager } from "./authentication.ts";
import { authLink, httpLink } from "./client/links.ts";

if (import.meta.env.VITE_ACDC2_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_ACDC2_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider
        userManager={userManager}
        onSigninCallback={onSigninCallback}
      >
        <ApolloProvider client={client}>
          <ThemeProvider defaultTheme="system" storageKey="vite-ui-theme">
            <App />
          </ThemeProvider>
        </ApolloProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
