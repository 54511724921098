import { Dispatch, useId, useState } from "react";
import {
  EditorAction,
  PaneIdentifier,
  TabIdentifier,
} from "../../editor/reducers";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";
import { Textarea } from "@acdc2/ui/components/textarea";
import { AccessButton } from "@acdc2/ui/components/access-button";
import {
  ExpressionCreationPaneFragment,
  useCreateExpressionAttributeMutation,
} from "../../client/generated";
import { useTranslation } from "react-i18next";
import KaTeXPreviewScrollArea from "./KaTeXPreviewScrollArea";
import { Label } from "@acdc2/ui/components/label";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: ExpressionCreationPaneFragment;
};

export default function ExpressionCreationPane({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [latexExpression, setLatexExpression] = useState<string>();

  const previewAreaId = useId();
  const textAreaId = useId();

  const [createExpressionAttribute] = useCreateExpressionAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!latexExpression) return;

    await createExpressionAttribute({
      variables: { regionId: fragment.id, expressionLatex: latexExpression },
    });

    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: PaneIdentifier.Default,
      },
    });
  };

  return (
    <AttributesPane value={PaneIdentifier.ExpressionCreation}>
      <AttributesPaneControls dispatch={dispatch} />
      <form className="p-2 flex flex-col gap-2" onSubmit={onSubmit}>
        <div className="flex flex-col gap-2">
          <Label htmlFor={previewAreaId}>
            <span>{t("ExpressionCreationPane.labels.preview")}</span>
          </Label>
          <KaTeXPreviewScrollArea
            latexExpression={latexExpression ?? ""}
            id={previewAreaId}
          />
        </div>

        <div className="flex flex-col gap-2">
          <Label htmlFor={textAreaId}>
            <span>{t("ExpressionCreationPane.labels.textarea")}</span>
          </Label>
          <Textarea
            id={textAreaId}
            required
            placeholder={t("ExpressionCreationPane.placeholder")}
            value={latexExpression}
            onChange={(event) => setLatexExpression(event.target.value)}
          />
        </div>

        <AccessButton type="submit">
          {t("ExpressionCreationPane.labels.add")}
        </AccessButton>
      </form>
    </AttributesPane>
  );
}
