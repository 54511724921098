import { mdiVectorSelection } from "@mdi/js";
import { Icon } from "@mdi/react";
import { useTranslation } from "react-i18next";

export default function SidebarMissingSelection(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="h-full flex flex-col justify-center items-center gap-2">
      <Icon path={mdiVectorSelection} size={3} />
      <span className="font-bold">{t("SidebarMissingSelection.label")}</span>
    </div>
  );
}
