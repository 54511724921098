import { useTranslation } from "react-i18next";
import { Dispatch } from "react";
import { EditorAction, EditorState, TabIdentifier } from "./reducers";
import SidebarDivider from "../shell/SidebarDivider";
import SidebarTabContent from "../shell/SidebarTabContent";
import RegionsToolbox from "../regions/RegionsToolbox";
import { EditorRegionsSidebarTabContentFragment } from "../client/generated";
import RegionPreview from "../regions/RegionPreview";

type Props = {
  state: EditorState;
  dispatch: Dispatch<EditorAction>;
  fragment: EditorRegionsSidebarTabContentFragment;
};

export default function EditorRegionsSidebarTabContent({
  state,
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const sortedRegions = [...fragment.regions].sort((a, b) => {
    const aTop = a.shape.aabb.top;
    const bTop = b.shape.aabb.top;
    if (aTop !== bTop) return aTop - bTop;

    const aLeft = a.shape.aabb.left;
    const bLeft = b.shape.aabb.left;
    return aLeft - bLeft;
  });

  return (
    <SidebarTabContent
      value={TabIdentifier.Regions}
      title={t("EditorRegionsSidebarTabContent.title")}
    >
      <div className="p-4">
        <RegionsToolbox state={state} dispatch={dispatch} />
      </div>
      <SidebarDivider />
      <div className="p-4 flex flex-col gap-4">
        {sortedRegions.map((region) => (
          <RegionPreview
            key={region.id}
            fragment={region}
            state={state}
            dispatch={dispatch}
          />
        ))}
      </div>
    </SidebarTabContent>
  );
}
