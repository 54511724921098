import { Dispatch } from "react";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";
import { EditorAction } from "../../editor/reducers";
import { TableCellUpdatePaneFragment } from "../../client/generated";

type Props = {
  fragment: TableCellUpdatePaneFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function TableCellUpdatePane({
  fragment,
  dispatch,
}: Props): JSX.Element {
  return (
    <AttributesPane value={fragment.id}>
      <AttributesPaneControls dispatch={dispatch} />
      Table Cell Update Pane for {fragment.id}
    </AttributesPane>
  );
}
