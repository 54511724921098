import { useTranslation } from "react-i18next";
import AttributePreview from "../AttributePreview";
import { ListPreviewFragment } from "../../client/generated";

type Props = {
  fragment: ListPreviewFragment;
};

export default function ListPreview({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <AttributePreview
      label={t("ListPreview.label")}
      preview={t(`ListPreview.preview.${fragment.listType}`)}
    />
  );
}
