import { useTranslation } from "react-i18next";
import AttributePreview from "../AttributePreview";
import { TextPreviewFragment } from "../../client/generated";

type Props = {
  fragment: TextPreviewFragment;
};

export default function TextPreview({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <AttributePreview label={t("TextPreview.label")} preview={fragment.text} />
  );
}
