import { Dispatch, useState } from "react";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";
import {
  EditorAction,
  PaneIdentifier,
  TabIdentifier,
} from "../../editor/reducers";
import AttributesPaneControls from "../AttributesPaneControls";
import AttributesPane from "../AttributesPane";
import {
  ListType,
  ListUpdatePaneFragment,
  useUpdateListAttributeMutation,
} from "../../client/generated";
import ListTypeSelect from "./ListTypeSelect";

type Props = {
  fragment: ListUpdatePaneFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function ListUpdatePane({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [listType, setListType] = useState(fragment.listType);

  const [updateListAttribute, { loading }] = useUpdateListAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await updateListAttribute({
      variables: {
        attributeId: fragment.id,
        listType,
      },
    });

    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: PaneIdentifier.Default,
      },
    });
  };

  return (
    <AttributesPane value={fragment.id}>
      <AttributesPaneControls dispatch={dispatch} />
      <form className="p-2 flex flex-col gap-2" onSubmit={onSubmit}>
        <ListTypeSelect
          name="listType"
          value={listType}
          onValueChange={(newListType) => setListType(newListType as ListType)}
        />
        <AccessButton loading={loading} type="submit">
          {t("ListUpdatePane.labels.confirm")}
        </AccessButton>
      </form>
    </AttributesPane>
  );
}
