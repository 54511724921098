import { useTranslation } from "react-i18next";
import AttributePreview from "../AttributePreview";
import { PaginationArtifactPreviewFragment } from "../../client/generated";

type Props = {
  fragment: PaginationArtifactPreviewFragment;
};

export default function PaginationArtifactPreview({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <AttributePreview
      label={t("PaginationArtifactPreview.label")}
      preview={t(`PaginationArtifactPreview.preview.${fragment.type}`)}
    />
  );
}
