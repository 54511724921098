import { RegionThumbnailFragment } from "../client/generated";
import { useProtectedImageSource } from "../client/useProtectedImage";

type Props = {
  fragment: RegionThumbnailFragment;
};

export default function RegionThumbnail({ fragment }: Props): JSX.Element {
  const svgSrc = useProtectedImageSource(fragment.page.svgUrl);

  const viewBoxMinX = fragment.shape.aabb.left * fragment.page.width;
  const viewBoxMinY = fragment.shape.aabb.top * fragment.page.height;
  const viewBoxWidth = fragment.shape.aabb.width * fragment.page.width;
  const viewBoxHeight = fragment.shape.aabb.height * fragment.page.height;

  return (
    <svg
      viewBox={`${viewBoxMinX} ${viewBoxMinY} ${viewBoxWidth} ${viewBoxHeight}`}
      preserveAspectRatio="none"
      className="bg-white"
    >
      <image
        xlinkHref={svgSrc}
        width={fragment.page.width}
        height={fragment.page.height}
      />
    </svg>
  );
}
