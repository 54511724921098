export enum TabIdentifier {
  Checker = "checker-editor-tab",
  Metadata = "metadata-editor-tab",
  Pages = "pages-editor-tab",
  Regions = "regions-editor-tab",
  Attributes = "attributes-editor-tab",
  ReadingOrder = "reading-order-editor-tab",
}

export enum PaneIdentifier {
  Default = "default-pane",
  TextCreation = "text-creation-pane",
  AlternativeTextCreation = "alternative-text-creation-pane",
  CaptionCreation = "caption-creation-pane",
  ExpressionCreation = "expression-creation-pane",
  FigureCreation = "figure-creation-pane",
  HeadingLevelCreation = "heading-level-creation-pane",
  TableCreation = "table-creation-pane",
  TableCellCreation = "table-cell-creation-pane",
  TableHeaderCellCreation = "table-header-cell-creation-pane",
  ListCreation = "list-creation-pane",
  ListItemCreation = "list-item-creation-pane",
  ImageCreation = "image-creation-pane",
  ExcludeReadingOrderCreation = "exclude-reading-order-creation-pane",
  PaginationArtifactCreation = "pagination-artifact-creation-pane",
  TableOfContentsCreation = "table-of-contents-creation-pane",
  TableOfContentsItemCreation = "table-of-contents-item-creation-pane",
}

export type PaneState = {
  tabIdentifier: TabIdentifier;
  paneIdentifier: PaneIdentifier | string;
};

export enum RegionMode {
  Rectangle = "rectangle",
  Polygon = "polygon",
}

export type SwitchRegionModeEditorAction = {
  type: "switchMode";
  mode: RegionMode;
};

export type SelectRegionEditorAction = {
  type: "selectRegion";
  regionId: string;
};

export type DeselectRegionEditorAction = {
  type: "deselectRegion";
};

export type SwitchPaneEditorAction = {
  type: "switchPane";
  paneState: PaneState;
};

export type EditorAction =
  | SwitchRegionModeEditorAction
  | SelectRegionEditorAction
  | DeselectRegionEditorAction
  | SwitchPaneEditorAction;

export type EditorState = {
  mode: RegionMode;
  paneState: PaneState;
  selectedRegionId?: string;
};

export function editorReducer(
  state: EditorState,
  action: EditorAction,
): EditorState {
  if (action.type === "switchMode") {
    return {
      ...state,
      mode: action.mode,
    };
  }

  if (action.type === "selectRegion") {
    return {
      ...state,
      selectedRegionId: action.regionId,
      paneState: {
        ...state.paneState,
        paneIdentifier: PaneIdentifier.Default,
      },
    };
  }

  if (action.type === "deselectRegion") {
    return {
      ...state,
      selectedRegionId: undefined,
      paneState: {
        ...state.paneState,
        paneIdentifier: PaneIdentifier.Default,
      },
    };
  }

  if (action.type === "switchPane") {
    return {
      ...state,
      paneState: action.paneState,
    };
  }

  return state;
}
