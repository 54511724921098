import { Button } from "@acdc2/ui/components/button";
import { ButtonIconLabel } from "@acdc2/ui/components/button-icon-label";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@acdc2/ui/components/dialog";
import { DropdownMenuItem } from "@acdc2/ui/components/dropdown-menu";
import { mdiExport } from "@mdi/js";
import { Trans, useTranslation } from "react-i18next";
import { DocumentExportDropdownMenuItemFragment } from "../client/generated";
import DocumentExtensionCombobox from "./DocumentExtensionCombobox";

type Props = {
  fragment: DocumentExportDropdownMenuItemFragment;
};

export default function DocumentExportDropdownMenuItem({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <Dialog>
      <DialogTrigger asChild>
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <ButtonIconLabel
            path={mdiExport}
            description={t("DocumentExportDropdownMenuItem.label")}
          />
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t("DocumentExportDropdownMenuItem.dialog.title")}
          </DialogTitle>
          <DialogDescription>
            <Trans
              i18nKey="DocumentExportDropdownMenuItem.dialog.description"
              components={{ "word-break": <span className="break-all" /> }}
              values={{ name: fragment.name }}
            />
          </DialogDescription>
        </DialogHeader>
        <DocumentExtensionCombobox fragment={fragment.members} />
        <DialogFooter>
          <Button type="submit">
            {t("DocumentExportDropdownMenuItem.dialog.button")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
