import { useTranslation } from "react-i18next";
import { Accordion } from "@acdc2/ui/components/accordion";
import { EditorMetadataSidebarTabContentFragment } from "../client/generated";
import { TabIdentifier } from "./reducers";
import SidebarTabContent from "../shell/SidebarTabContent";
import MetadataAuthorAccordionItem from "../metadata/MetadataAuthorAccordionItem";
import MetadataKeywordAccordionItem from "../metadata/MetadataKeywordAccordionItem";
import MetadataDateAccordionItem from "../metadata/MetadataDateAccordionItem";
import MetadataTitleAccordionItem from "../metadata/MetadataTitleAccordionItem";

type Props = {
  fragment: EditorMetadataSidebarTabContentFragment;
};

export default function EditorMetadataSidebarTabContent({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabContent
      value={TabIdentifier.Metadata}
      title={t("EditorMetadataSidebarTabContent.title")}
    >
      <Accordion
        type="multiple"
        defaultValue={["titles", "authors", "keywords", "date"]}
        className="w-full"
      >
        <MetadataTitleAccordionItem fragment={fragment} />
        <MetadataAuthorAccordionItem fragment={fragment} />
        <MetadataKeywordAccordionItem fragment={fragment} />
        <MetadataDateAccordionItem fragment={fragment} />
      </Accordion>
    </SidebarTabContent>
  );
}
