import { Dispatch, useState } from "react";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";
import {
  EditorAction,
  PaneIdentifier,
  TabIdentifier,
} from "../../editor/reducers";
import {
  CaptionUpdatePaneFragment,
  useUpdateCaptionAttributeMutation,
} from "../../client/generated";
import CaptionRegionSelect from "./CaptionRegionSelect";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";

type Props = {
  fragment: CaptionUpdatePaneFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function CaptionUpdatePane({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [linkedRegion, setLinkedRegion] = useState(
    fragment.linkedRegionId ? fragment.linkedRegionId : "",
  );

  const [captionUpdate, { loading }] = useUpdateCaptionAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await captionUpdate({
      variables: {
        attributeId: fragment.id,
        linkedRegionId: linkedRegion,
      },
    });

    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: PaneIdentifier.Default,
      },
    });
  };

  return (
    <AttributesPane value={fragment.id}>
      <AttributesPaneControls dispatch={dispatch} />
      <form className="p-2 flex flex-col gap-2" onSubmit={onSubmit}>
        <CaptionRegionSelect
          fragment={fragment.region}
          value={linkedRegion}
          onValueChange={setLinkedRegion}
        />
        <AccessButton type="submit" loading={loading}>
          {t("CaptionUpdatePane.labels.confirm")}
        </AccessButton>
      </form>
    </AttributesPane>
  );
}
