import { useTranslation } from "react-i18next";
import { TabIdentifier } from "./reducers";
import SidebarTabContent from "../shell/SidebarTabContent";

export default function EditorCheckerSidebarTabContent(): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabContent
      value={TabIdentifier.Checker}
      title={t("EditorCheckerSidebarTabContent.title")}
    >
      Accessibility Checker Content
    </SidebarTabContent>
  );
}
