import { mdiFileDocumentOutline } from "@mdi/js";
import { useTranslation } from "react-i18next";
import SidebarTabButton from "../shell/SidebarTabButton";
import { TabIdentifier } from "./reducers";

export default function EditorMetadataSidebarTabButton(): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabButton
      tooltip={t("EditorMetadataSidebarTabButton.tooltip")}
      value={TabIdentifier.Metadata}
      iconPath={mdiFileDocumentOutline}
    />
  );
}
