import { ScrollArea, ScrollBar } from "@acdc2/ui/components/scroll-area";
import KaTeX from "./KaTeX";

type Props = {
  latexExpression: string;
  id?: string;
};

export default function KaTeXPreviewScrollArea({
  latexExpression,
  id,
}: Props): JSX.Element {
  return (
    <ScrollArea className="rounded-md border bg-secondary p-4" id={id}>
      <KaTeX expression={latexExpression} />
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
}
