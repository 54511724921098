import { Icon } from "@mdi/react";
import { Button } from "@acdc2/ui/components/button";
import { forwardRef } from "react";

type Props = {
  path: React.ComponentProps<typeof Icon>["path"];
  disabled?: boolean;
  onClick?: () => void;
};

const SidebarButtonIcon = forwardRef<HTMLButtonElement, Props>(
  function (props, ref) {
    const { path, disabled, onClick, ...otherProps } = props;

    return (
      <Button
        {...otherProps}
        ref={ref}
        variant="ghost"
        size="icon"
        disabled={disabled}
        onClick={onClick}
      >
        <Icon path={path} size={1} />
      </Button>
    );
  },
);

export default SidebarButtonIcon;
